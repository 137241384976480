<template>
  <div class="thank-you-page">
    <category-block
      :target="category"
      :container="true"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container fluid>
      <CategoryTitle :category="category" />

      <strong class="fidelity-card-block d-block my-6" v-if="user.fidelityCard"
        >{{ $t("registrationCompleted.card") }} {{ user.fidelityCard }}</strong
      >

      <category-block
        :target="category"
        :container="false"
        position="position2"
        class="category-block category-block-2"
      />

      <div class="actions-button d-flex flex-column">
        <v-btn
          large
          depressed
          :to="{ name: 'ProfileUpdate' }"
          color="primary"
          width="250"
          class="mb-6"
        >
          {{ $t("registrationCompleted.goToProfile") }}
        </v-btn>
        <v-btn
          large
          depressed
          :to="{ name: 'HomeSite' }"
          color="primary"
          width="250"
        >
          {{ $t("registrationCompleted.goToHomeBtn") }}
        </v-btn>
      </div>
    </v-container>

    <category-block
      :target="category"
      :container="true"
      position="position3"
      class="category-block category-block-3"
    />
    <category-block
      :target="category"
      :container="true"
      position="position4"
      class="category-block category-block-4"
    />

    <v-container fluid>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";

import category from "~/mixins/category";

import { mapGetters } from "vuex";

export default {
  name: "RegistrationCompleted",
  mixins: [category],
  components: { CategoryTitle },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    async handleLogin() {
      if (await this.doLogin("RegistrationCompleted")) {
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    }
  }
};
</script>
